import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public goToLinkedIn(): void {
    window.open('https://www.linkedin.com/in/william-harris-weaver-34203789/');
  }

  public email(): void {
    window.open('mailto:harrisweaver@gmail.com');
  }

  public goToRoot76(): void {
    window.open('https://root76.io');
  }

}
