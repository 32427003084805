<div class="workItem">
    <img src="/assets/portfolio/waterWells.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Water Wells</h2>
            <h4>Ohio Department of Natural Resources</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for the state of Ohio. This responsive web application allows state employees to view and manage all of the state's Water Wells, file new Well Logs, create Sealing Reports, query using a variety of GIS tools, manage accounts for private Drilling Companies, generate dynamic images of Well Logs, customize Dashboards, customize spatial symbology, and much more.</p>
            <p>Utilizes Angular 12, Leaflet, Typescript</p>
            <p>Developed at Johnson, Mirmiran and Thompson.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/westva.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>LRS Platform</h2>
            <h4>West Virginia Division of Natural Resources</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for West Virginia residents and out-of-state visitors. This responsive web application allows the public to purchase hunting and fishing licenses as well as related items, report their harvests, and manage their portfolio. System users with sufficient priveleges can manage purchase-able products, set season dates for hunting and fishing licenses, manage customer accounts, and much more.</p>
            <p>Utilizes Angular 10, Leaflet, Typescript.</p>
            <p>Developed at Johnson, Mirmiran and Thompson.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/ada.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>ADA Compliance</h2>
            <h4>Delaware Department of Transportation</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for Deleware DOT employees. This responsive web application allows inspectors in the field to log a variety of measurements to confirm ADA compliance for public structures such as sidewalks, bus stops, crosswalks, curb ramps, shared use paths. Complex real-time validation combined with Angular's form controls allow for a intuitive and friendly user experience, and a web map provides easy access to facility location data.</p>
            <p>Utilizes Angular 8, Leaflet, Typescript.</p>
            <p>Developed at Johnson, Mirmiran and Thompson.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/odfw.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>LRS Platform</h2>
            <h4>Oregon Department of Fish and Wildlife</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for Oregon residents and out-of-state visitors. Similar to our West Virginia implementation and derived from the same internal Angular framework, this app allows in-state and out-of-state residents to purchase and manage their hunting and fishing licenses, and provides relevant functionality such as reporting the details and location of harvests. Internal users can manage products, customer accounts, roles and permissions, etc.</p>
            <p>Utilizes Angular 10, Leaflet, Typescript.</p>
            <p>Developed at Johnson, Mirmiran and Thompson.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/vdot.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Pathways for Planning</h2>
            <h4>Virginia Department of Transportation</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for VDOT employees. Pathways for Planning allows VDOT to visualize large amounts of geospatial data to drive their business decisions, create re-usable data-schemas, assign permissions to state-level groups and organizations, and manage spatial datasets with cutting-edge drawing and editing tools.</p>
            <p>Utilizes Angular 5, Leaflet, Typescript.</p>
            <p>Developed at Timmons Group.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/maine.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Citizen Science</h2>
            <h4>Maine Department of Inland Fisheries and Wildlife</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for state residents. Citizen Science is an initiative to get the local population involved in conservation efforts and wildlife tracking. The app allows citizens to visualize geospatial data for different wildlife programs and submit their own wildlife surveys at selectable locations on a web map.</p>
            <p>Utilizes AngularJS, Leaflet, and Bootstrap.</p>
            <p>Developed at Timmons Group.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/vdgif.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Find Wildlife Virginia</h2>
            <h4>Virginia Department of Game and Inland Fisheries</h4>
        </div>
        <div class="descBody">
            <p>A Web-App for locals and tourists alike. Find Wildlife Virginia lets users find the best places to Hunt, Fish, Bird-Watch, and go Boating. All of the data is visualized on a lightweight, responsive web map and features a robust amount of data, all the way down to specific species.</p>
            <p>Utilizes AngularJS, Leaflet, and Bootstrap.</p>
            <p>Developed at Timmons Group.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/root76.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>ROOT 76</h2>
            <h4>Company Homepage</h4>
        </div>
        <div class="descBody">
            <p>Our website for the public audience. Founded in 2014, ROOT 76 is comprised of a small, super-talented group of artists and developers on a mission to create the world's greatest video games.</p>
            <p>Utilizes Bootstrap and Textillate.js.</p>
            <p>Developed Independently. Designed by Mike Odum, the lead artist.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/cct.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Clash Cup Turbo</h2>
            <h4>Game Splashpage</h4>
        </div>
        <div class="descBody">
            <p>A responsive, non-scrolling teaser for the public audience. Clash Cup Turbo is ROOT 76's first major title release, slated for XBOX ONE, PS4, and PC via Steam. The game is in active development and estimated to be released early next year.</p>
            <p>Designed and Developed Independently.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/profitlink.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Profitlink</h2>
            <h4>Homepage for Web App</h4>
        </div>
        <div class="descBody">
            <p>A responsive website for ProfitLink, an app that allows farmers to analyze their yields, divide their lands into trackable zones, assess soil fertility, monitor and record crops throughout the season, calculate ROI, and much more.</p>
            <p>Utilizes Bootstrap.</p>
            <p>Developed at Timmons Group.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/modum.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Mike Odum</h2>
            <h4>Responsive Art Portfolio</h4>
        </div>
        <div class="descBody">
            <p>The home-page of Mike Odum, one of my good friends and the lead artist of ROOT 76.</p>
            <p>Utilizes Bootstrap.</p>
            <p>Developed Independently. Designed by Mike himself.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/daywon.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>DayWon</h2>
            <h4>Web App / CRM</h4>
        </div>
        <div class="descBody">
            <p>This Google-based responsive web application connects your Contacts, Events, Tasks and Tags in highly useful ways. It aims to utilize already-existing data in your Google Account to deliver a pleasant, simple user experience that keeps your daily life organized.</p>
            <p>Utilizes Ember.js and Bootstrap.</p>
            <p>Developed at Evenspring.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/tree.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Tree Trails</h2>
            <h4>Web App</h4>
        </div>
        <div class="descBody">
            <p>Designed to collect information about trees throughout the State of Texas. The app was created to allow teachers, youth organization leaders, and the general public to create a custom tree trail and utilize the corresponding educational curriculum to learn about these threes and share this information with others. Features include the ability to navigate through maps of trails and trees, accumulate statistics about collected trails and trees, toggle between aerial and street views, and GPS capabilities.</p>
            <p>Utilizes ArcGIS JS-API.</p>
            <p>Developed at Evenspring with Timmons Group.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/mmc.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Monticello Motor Club</h2>
            <h4>Responsive Homepage</h4>
        </div>
        <div class="descBody">
            <p>A Wordpress solution designed to give the client complete control over the content of their website -- from generating new sections on their landing pages to managing their own E-Commerce platform. <i>"Monticello Motor Club, North America’s Premier Automotive Country Club and Private Race Track, features 4.1 miles of smooth race-grade asphalt dedicated to the driving pleasure of its members – individuals and families who are passionate about motorsports, who relish the driving experience, and who seek a place of belonging among like-minded people."</i></p>
            <p>Utilizes Wordpress and Bootstrap.</p>
            <p>Developed at Evenspring.</p>
        </div>
    </div>
</div>
<div class="workItem">
    <img src="/assets/portfolio/rwl.jpg" alt="">
    <div class="description">
        <div class="descHeader">
            <h2>Realtor With Locs</h2>
            <h4>Real Estate Homepage</h4>
        </div>
        <div class="descBody">
            <p>A responsive Wordpress solution for Chase Parker, real estate extraordinaire. Chase practices is an an agent with Long and Foster in Vienna, VA: <i>My goal is to help customers navigate the myriad aspects and investment opportunities of real estate. I will go above and beyond to educate my customers in the proper steps to buy, sell and invest in real estate. I look forward to the opportunity to work with you to help you realize your real estate goals.</i></p>
            <p>Utilizes Wordpress and Bootstrap.</p>
            <p>Developed Independently. Designed by Mike Odum.</p>
        </div>
    </div>
</div>
