<div class="contactItem" (click)="goToLinkedIn()">
  <img src="/assets/linkedin.png" />
  <img src="/assets/linkedin_white.png" class="hoverImage" />
  <span>LinkedIn</span>
</div>
<div class="contactItem" (click)="email()">
  <img src="/assets/email.png" />
  <img src="/assets/email_white.png" class="hoverImage" />
  <span>Email</span>
</div>
<div class="contactItem" (click)="goToRoot76()">
  <img src="/assets/root76.png" />
  <img src="/assets/root76_white.png" class="hoverImage" />
  <span>ROOT76</span>
</div>
