<div class="borderBar">
  <ng-container *ngFor="let borderBar of numberOfBorderBars">
    <div class="bar bar0"></div>
    <div class="bar bar1"></div>
    <div class="bar bar2"></div>
    <div class="bar bar3"></div>
  </ng-container>
</div>
<h5 class="jobTitle" [ngClass]="{'routeActivated': routeActivated}">FRONT END DEVELOPER</h5>
<div class="logoContainer" [ngClass]="{'routeActivated': routeActivated}">
  <img src="../assets/logo.jpg" class="logo" (click)="returnHome()" />
  <h1 class="nametag first">WILLIAM</h1>
  <h1 class="nametag last">WEAVER</h1>
</div>
<span class="mainNav" [ngClass]="{'routeActivated': routeActivated}">
  <a (click)="openSection('about')" class="navItem nav0">About</a>
  <!-- <span class="slash mainSlash slash0">/</span> -->
  <a (click)="openSection('work')" class="navItem nav1">Work</a>
  <!-- <span class="slash mainSlash slash1">/</span> -->
  <a (click)="openSection('contact')" class="navItem nav2">Contact</a>
</span>
<div class="routeContainer" [ngClass]="{'routeActivated': routeActivated}">
  <h2 class="pageTitle" *ngIf="pageTitle">{{pageTitle}}</h2>
  <img (click)="returnHome()" class="backButton" src="../assets/backArrow.png" />
  <div class="routeSubContainer" [ngClass]="{'transition': transition}">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="backgroundLogos">
  <img src="../assets/design_pattern.png" class="backgroundLogo logo1" />
  <img src="../assets/design_pattern.png" class="backgroundLogo logo2" />
</div>
<div class="bubbles">
  <ng-container *ngFor="let bottomBubble of bottomBubbles">
    <img class="bubble bottomBubble" [ngClass]="{'bubble-moving': bottomBubble.moving}" [ngStyle]="bottomBubble.style" src="../assets/bubble.png" />
  </ng-container>
  <ng-container *ngFor="let startingBubble of startingBubbles">
    <img class="bubble startingBubble" [ngClass]="{'bubble-moving': startingBubble.moving}" [ngStyle]="startingBubble.style" src="../assets/bubble.png" />
  </ng-container>
</div>
