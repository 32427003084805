import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public startingBubbles: any[] = [];
  public bottomBubbles: any[] = [];
  public routeActivated: boolean = false;
  public transition: boolean = false;
  public pageTitle: string = '';

  public numberOfBorderBars: any[] = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0
  ];
  public numberOfStartingBubbles: number = 15;
  public numberOfBottomBubbles: number = 18;

  constructor(
    private router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url !== "/") {
          this.routeActivated = true;
          this.pageTitle = event.url.substring(1);
        } else {
          this.routeActivated = false;
        }
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      document.getElementsByClassName('maxHeight')[0].appendChild(document.createTextNode(
          '@keyframes float-bubble {'
        +		'to {'
        +	    	'transform: translateY(-' + (window.innerHeight + 100) + 'px' + ');'
        +		'}'
        +	'}'
        +	'@keyframes float-logo {'
        +		'to {'
        +	    	'transform: translateX(-' + window.innerWidth + 'px' + ') translateY(-' + window.innerHeight + 'px' + ');'
        +		'}'
        +	'}'
      ));

      document.getElementsByClassName('bubbleSpeeds')[0].appendChild(document.createTextNode(
          '.bubble-moving {'
        +	'	-webkit-animation: float-bubble 50s linear forwards infinite;'
        +	  	'animation: float-bubble 50s linear forwards infinite;'
        +	'}'
      ));

      this.startingBubbles = Array.from(document.getElementsByClassName('startingBubble') as HTMLCollectionOf<HTMLElement>);
      this.bottomBubbles = Array.from(document.getElementsByClassName('bottomBubble') as HTMLCollectionOf<HTMLElement>);

      let thisOpacity: number;
      let thisWidth: number;
      let thisHorizontal: number;
      let thisVertical: number;

      for (let i = 0; i < this.numberOfStartingBubbles; i++) {
        thisOpacity = Math.round(((Math.random() * 30) + 1) + 10);
        thisWidth = Math.round(((Math.random() * 30) + 1) + 9);
        thisHorizontal = Math.floor((Math.random() * 100) + 1);
        thisVertical = Math.round((Math.random() * 100) - 10);

        let bubble: any = {
          style: {
            opacity: '0.' + thisOpacity,
            width: thisWidth + 'px',
            left: thisHorizontal + '%',
            bottom: thisVertical + '%'
          },
          moving: false
        };

        this.startingBubbles.push(bubble);
        console.log(bubble);
        this.startAnimation(bubble);
      }

      for (let i = 0; i < this.numberOfBottomBubbles; i++) {
        thisOpacity = Math.round(((Math.random() * 40) + 1) + 10);
        thisWidth = Math.round(((Math.random() * 30) + 1) + 9);
        thisHorizontal = Math.floor((Math.random() * 100) + 1);

        let bubble: any = {
          style: {
            opacity: '0.' + thisOpacity,
            width: thisWidth + 'px',
            left: thisHorizontal + '%',
            bottom: '100%'
          },
          moving: false
        };

        this.bottomBubbles.push(bubble);
      }

      this.setupAnimations();
    }, 10);
  }

  private setupAnimations(): void {
    document.getElementsByClassName('backgroundLogo')[0].classList.add('logo-moving');
    document.getElementsByClassName('backgroundLogo')[1].classList.add('logo-moving');
    document.getElementsByClassName('logo')[0].classList.add('active');

    setTimeout(() => {
      document.getElementsByClassName('first')[0].classList.add('active');
      (document.getElementsByClassName('bubbles')[0] as HTMLElement).style.opacity = "1";
      this.startBottomBubbles();

      setTimeout(() => {
        document.getElementsByClassName('last')[0].classList.add('active');
        setTimeout(() => {
          document.getElementsByClassName('jobTitle')[0].classList.add('active');
          setTimeout(() => {
            let navItems = Array.from(document.getElementsByClassName('navItem') as HTMLCollectionOf<HTMLElement>);
            let i = 0;
            let addNavItems = setInterval(() => {
              if (i < navItems.length) {
                navItems[i].classList.add('active');

                i++;
              } else {
                clearInterval(addNavItems);
                document.getElementsByClassName('preload')[0].insertAdjacentHTML('beforeend', '<img src="/assets/laguna.jpg" /><img src="/assets/linkedin.png" /><img src="/assets/linkedin_white.png" /><img src="/assets/email.png" /><img src="/assets/email_white.png" /><img src="/assets/root76.png" /><img src="/assets/root76_white.png" /><img src="/assets/portfolio/westva.jpg" alt=""><img src="/assets/portfolio/ada.jpg" alt=""><img src="/assets/portfolio/odfw.jpg" alt=""><img src="/assets/portfolio/vdot.jpg" alt=""><img src="/assets/portfolio/maine.jpg" alt=""><img src="/assets/portfolio/vdgif.jpg" alt=""><img src="/assets/portfolio/root76.jpg" alt=""><img src="/assets/portfolio/cct.jpg" alt=""><img src="/assets/portfolio/profitlink.jpg" alt=""><img src="/assets/portfolio/modum.jpg" alt=""><img src="/assets/portfolio/daywon.jpg" alt=""><img src="/assets/portfolio/tree.jpg" alt=""><img src="/assets/portfolio/mmc.jpg" alt=""><img src="/assets/portfolio/rwl.jpg" alt="">');
                // document.getElementsByClassName('slash0')[0].classList.add('active');
                // document.getElementsByClassName('slash1')[0].classList.add('active');
              }
            }, 400);
          }, 200);
        }, 400);
      }, 300);
    }, 400);

    setTimeout(() => {
      document.getElementsByClassName('startingBubble')[0].remove();
    }, 49000)
  }

  private startAnimation(bubble: any): void {
    bubble.moving = true;
  }

  private startBottomBubbles(): void {
    let i = 0;
  	let animateBubbles = setInterval(() => {
  		if (i == this.bottomBubbles.length) {
  			clearInterval(animateBubbles);
  		} else {
  			this.bottomBubbles[i].style.bottom = -10 + '%';
  			this.startAnimation(this.bottomBubbles[i]);
  			i++;
  		}
  	}, 3000);
  }

  public openSection(route: string): void {
    this.pageTitle = route;
    if (this.routeActivated) {
      this.transition = true;
      setTimeout(() => {
        this.router.navigate([route]);
        this.transition = false;
      }, 500);
    } else {
      this.routeActivated = true;
      this.router.navigate([route]);
    }
  }

  public returnHome(): void {
    this.routeActivated = false;
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }

}
